import {Component, Input} from '@angular/core';
import {IModule} from "../../../../../interfaces/IModule";
import {EpisodeOfCareService} from "../../../../../services/episodeOfCare.service";

@Component({
  selector: 'app-social-history-section',
  templateUrl: './social-history-section.component.html',
  styleUrls: ['./social-history-section.component.css']
})
export class SocialHistorySectionComponent {
  @Input() submenu?: IModule[];

  constructor(private readonly eocService: EpisodeOfCareService) {}

  ngOnInit() {
  }
}
