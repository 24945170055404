import { Component, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TooltipComponent } from '../../../../../../../common/tooltip/tooltip.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { CapnographyService } from 'src/app/services/capnography.service';
import { IAddCapnography } from 'src/app/interfaces/Capnography/IAddCapnography';

@Component({
  standalone: true,
  selector: 'app-capnography-add-edit',
  templateUrl: './capnography-add-edit.component.html',
  styleUrls: ['./capnography-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
  ],
})
export class CapnographyAddEditComponent {
  private destroy$ = new Subject<void>();

  isVisible = false;
  isLoading = false;

  isEdit = false;
  editCapnographyId?: number;

  capnographySubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  capnographyForm: FormGroup = new FormGroup({});

  constructor(
    private readonly capnographyService: CapnographyService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;

    this.capnographyForm = this.formBuilder.group({
      dateValue: [null, Validators.required],
      positiveEndExpiratoryPressureValue: [null, Validators.required],
      endTidalValue: [null, Validators.required],
      fractionInhaledOxygenValue: [null, Validators.required],
      tidalVolumeValue: [null, Validators.required],
      respiratoryRateValue: [null, Validators.required],
      pressureInhaledOxygenValue: [null, Validators.required],
    });

    this.capnographyService.capnographyEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editCapnographyId = data?.id;

          this.capnographyForm.patchValue({
            dateValue: data?.date,
            positiveEndExpiratoryPressureValue:
              data?.positiveEndExpiratoryPressure,
            endTidalValue: data?.endTidal,
            fractionInhaledOxygenValue: data?.fractionInhaledOxygen,
            tidalVolumeValue: data?.tidalVolume,
            respiratoryRateValue: data?.respiratoryRate,
            pressureInhaledOxygenValue: data?.pressureInhaledOxygen,
          });
        } else {
          this.isEdit = false;
          this.editCapnographyId = undefined;
          this?.capnographyForm.reset();
        }
      });

    this.capnographyService.capnographyModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });

    this.isLoading = false;
  }

  ngOnDestroy() {
    // Unsubscribe from observables
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  handleCancel() {
    this.capnographyForm.reset();

    this.capnographyService.capnographyModalSub.next(false);

    this.capnographySubmitted = false;

    this.isLoading = false;

    this?.modalRef.destroy();
  }

  async submitForm() {
    this.isLoading = true;
    this.capnographySubmitted = true;
    console.log(this.capnographyForm);

    if (this.capnographyForm.valid) {
      let capnographyObj: IAddCapnography = {};
      capnographyObj.date = this.capnographyForm.value?.dateValue;
      capnographyObj.positiveEndExpiratoryPressure =
        this.capnographyForm.value?.positiveEndExpiratoryPressureValue;
      capnographyObj.endTidal = this.capnographyForm.value?.endTidalValue;
      capnographyObj.fractionInhaledOxygen =
        this.capnographyForm.value?.fractionInhaledOxygenValue;
      capnographyObj.tidalVolume = this.capnographyForm.value?.tidalVolumeValue;
      capnographyObj.respiratoryRate =
        this.capnographyForm.value?.respiratoryRateValue;
      capnographyObj.pressureInhaledOxygen =
        this.capnographyForm.value?.pressureInhaledOxygenValue;
      console.log(capnographyObj);
      if (this.isEdit) {
        capnographyObj.id = this.editCapnographyId;
        await this.capnographyService
          .editCapnography(capnographyObj)
          .subscribe({
            next: () => {
              Swal.fire({
                text: 'Capnography edited successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.capnographyService.getData();

              this.handleCancel();
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to edit capnography!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
              console.log(err);

              this.handleCancel();
            },
          });
      } else {
        await this.capnographyService.addCapnography(capnographyObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Capnography added successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.capnographyService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to add capnography!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.log(err);

            this.handleCancel();
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }
}
