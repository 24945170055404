import { Component, Input } from '@angular/core';
import { IAllergyDetailed } from 'src/app/interfaces/Detailed/IAllergyDetailed';
import { ReusableTableExpandComponent } from 'src/app/common/reusable-table-expand/reusable-table-expand.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTableModule } from 'ng-zorro-antd/table';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import {IVaccinationDetailed} from "../../../../../../../interfaces/Vaccination/IVaccinationDetailed";

@Component({
  standalone: true,
  selector: 'app-vaccination-expand',
  templateUrl: './vaccination-expand.component.html',
  styleUrls: ['./vaccination-expand.component.css'],
  imports: [
    NzLayoutModule,
    NzTableModule,
    TranslateModule,
    CommonModule,
    ReusableTableExpandComponent,
    FlexModule,
  ],
})

export class VaccinationExpandComponent {
  @Input() item: IVaccinationDetailed = {};
}

