import { Component, Input } from '@angular/core';
import { IModule } from 'src/app/interfaces/IModule';
import { paths } from 'src/component-paths/component-paths';

@Component({
  selector: 'app-patient-characteristics',
  templateUrl: './patient-characteristics.component.html',
  styleUrls: ['./patient-characteristics.component.css'],
})
export class PatientCharacteristicsComponent {
  @Input() submenu?: IModule[];
  componentPaths = paths;
}
