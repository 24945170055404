import { Component } from '@angular/core';

@Component({
  selector: 'app-etiology-section',
  templateUrl: './etiology-section.component.html',
  styleUrls: ['./etiology-section.component.css']
})
export class EtiologySectionComponent {

}
