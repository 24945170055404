import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGenericServiceResponse } from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { IBrandNameDropdown } from '../interfaces/Medication/IBrandNameDropdown';
import { IAtcDropdown } from '../interfaces/Medication/IAtcDropdown';
import { IActiveSubstanceDropdown } from '../interfaces/Medication/IActiveSubstanceDropdown';
import { IPharmacyDetailed } from '../interfaces/Pharmacy/IPharmacyDetailed';

@Injectable()
export class PharmacyService {
  constructor(private readonly _http: HttpClient) {}

  getByBrandName(
    brandName: string
  ): Observable<IGenericServiceResponse<IPharmacyDetailed>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IPharmacyDetailed>>(
      `${environment.BACKEND_URL}Pharmacy/BrandName/${brandName}`,
      {
        headers: headers,
      }
    );
  }

  getByAtc(
    atc: string
  ): Observable<IGenericServiceResponse<IPharmacyDetailed>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IPharmacyDetailed>>(
      `${environment.BACKEND_URL}Pharmacy/Atc/${atc}`,
      {
        headers: headers,
      }
    );
  }

  getByActiveSubstance(
    actSub: string
  ): Observable<IGenericServiceResponse<IPharmacyDetailed>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IPharmacyDetailed>>(
      `${environment.BACKEND_URL}Pharmacy/ActiveSubstance/${actSub}`,
      {
        headers: headers,
      }
    );
  }

  getBrandNameDropdown(): Observable<
    IGenericServiceResponse<IBrandNameDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IBrandNameDropdown>>(
      `${environment.BACKEND_URL}Pharmacy/DropdownData/BrandName`,
      {
        headers: headers,
      }
    );
  }

  getAtcDropdown(): Observable<IGenericServiceResponse<IAtcDropdown>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IAtcDropdown>>(
      `${environment.BACKEND_URL}Pharmacy/DropdownData/ATC`,
      {
        headers: headers,
      }
    );
  }

  getActiveSubstanceDropdown(): Observable<
    IGenericServiceResponse<IActiveSubstanceDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IActiveSubstanceDropdown>>(
      `${environment.BACKEND_URL}Pharmacy/DropdownData/ActiveSubstance`,
      {
        headers: headers,
      }
    );
  }
}
