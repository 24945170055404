import { Component } from '@angular/core';

@Component({
  selector: 'app-clinical-examination-section',
  templateUrl: './clinical-examination-section.component.html',
  styleUrls: ['./clinical-examination-section.component.css']
})
export class ClinicalExaminationSectionComponent {

}
