import { Component, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TooltipComponent } from '../../../../../../../common/tooltip/tooltip.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { MedicalAlertService } from '../../../../../../../services/medicalAlert.service';
import { IAddMedicalInfo } from '../../../../../../../interfaces/MedicalInfo/IAddMedicalInfo';

@Component({
  standalone: true,
  selector: 'app-medical-alert-add-edit',
  templateUrl: './medical-alert-add-edit.component.html',
  styleUrls: ['./medical-alert-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
  ],
})
export class MedicalAlertAddEditComponent {
  private destroy$ = new Subject<void>();

  isVisible = false;
  isLoading = false;

  isEdit = false;
  editMedicalAlertId?: number;

  medicalAlertSubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  medicalAlertForm: FormGroup = new FormGroup({});

  constructor(
    private readonly medicalAlertService: MedicalAlertService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isEdit = false;
    this.isLoading = false;

    this.medicalAlertForm = this.formBuilder.group({
      descriptionValue: [null, Validators.required],
    });

    this.medicalAlertService.medicalAlertEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editMedicalAlertId = data?.id;

          this.medicalAlertForm.patchValue({
            descriptionValue: data?.description,
          });
        } else {
          this.isEdit = false;
          this.editMedicalAlertId = undefined;

          this?.medicalAlertForm.reset();
        }
      });

    this.medicalAlertService.medicalAlertModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  handleCancel() {
    this.medicalAlertForm.reset();

    this.medicalAlertService.medicalAlertModalSub.next(false);

    this.medicalAlertSubmitted = false;

    this.isLoading = false;

    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.medicalAlertSubmitted = true;

    if (this.medicalAlertForm.valid) {
      const medicalAlertObj: IAddMedicalInfo = {};
      medicalAlertObj.id = this.editMedicalAlertId;
      medicalAlertObj.description =
        this.medicalAlertForm.value?.descriptionValue;

      if (this.isEdit) {
        this.medicalAlertService.editMedicalAlert(medicalAlertObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Medical Alert edited successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.medicalAlertService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to edit medical alert!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.log(err);

            this.handleCancel();
          },
        });
      } else {
        this.medicalAlertService.addMedicalAlert(medicalAlertObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Medical Alert added successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.medicalAlertService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to add medical alert!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.log(err);
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }
}
