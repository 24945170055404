import { Component, Input } from '@angular/core';
import { IModule } from 'src/app/interfaces/IModule';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';

@Component({
  selector: 'app-pregnancy-section',
  templateUrl: './pregnancy-section.component.html',
  styleUrls: ['./pregnancy-section.component.css'],
})
export class PregnancySectionComponent {
  @Input() submenu?: IModule[];

  constructor(private readonly eocService: EpisodeOfCareService) {}

  ngOnInit() {}
}
