import { Component, Input } from '@angular/core';
import { ReusableTableExpandComponent } from 'src/app/common/reusable-table-expand/reusable-table-expand.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTableModule } from 'ng-zorro-antd/table';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import {IProcedureDetailed} from "../../../../../../../interfaces/Procedure/IProcedureDetailed";

@Component({
  standalone: true,
  selector: 'app-procedure-expand',
  templateUrl: './procedure-expand.component.html',
  styleUrls: ['./procedure-expand.component.css'],
  imports: [
    NzLayoutModule,
    NzTableModule,
    TranslateModule,
    CommonModule,
    ReusableTableExpandComponent,
    FlexModule,
  ],
})

export class ProcedureExpandComponent {
  @Input() item: IProcedureDetailed = {};
}

