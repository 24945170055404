import { Component, Input } from '@angular/core';
import { IAllergyDetailed } from 'src/app/interfaces/Detailed/IAllergyDetailed';
import { ReusableTableExpandComponent } from 'src/app/common/reusable-table-expand/reusable-table-expand.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTableModule } from 'ng-zorro-antd/table';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
@Component({
  standalone: true,
  selector: 'app-allergies-expand',
  templateUrl: './allergies-expand.component.html',
  styleUrls: ['./allergies-expand.component.css'],
  imports: [
    NzLayoutModule,
    NzTableModule,
    TranslateModule,
    CommonModule,
    ReusableTableExpandComponent,
    FlexModule,
  ],
})
export class AllergiesExpandComponent {
  @Input() item: IAllergyDetailed = {};

  ngOnInit() {}
}
