import {Component, Input} from '@angular/core';
import {IModule} from "../../../../../interfaces/IModule";
import {EpisodeOfCareService} from "../../../../../services/episodeOfCare.service";

@Component({
  selector: 'app-travel-history-section',
  templateUrl: './travel-history-section.component.html',
  styleUrls: ['./travel-history-section.component.css']
})
export class TravelHistorySectionComponent {
  @Input() submenu?: IModule[];

  constructor(private readonly eocService: EpisodeOfCareService) {}

  ngOnInit() {
  }
}
