import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { IGenericServiceResponse } from '../interfaces/IServiceResponse';
import { IModule } from '../interfaces/IModule';

@Injectable()
export class MenuService {
  constructor(private _http: HttpClient) {}

  getMenu(): Observable<IGenericServiceResponse<IModule[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IModule[]>>(
      `${environment.BACKEND_URL}Module`,
      {
        headers: headers,
      }
    );
  }
}
