import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  standalone: true,
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css'],
  imports: [CommonModule, NzToolTipModule, NzIconModule],
})
export class TooltipComponent {
  @Input() fieldName?: string;
  @Input() area?: string;

  description?: string;

  constructor(private readonly patientService: PatientService) {}

  ngOnInit() {
    // Find the description based on the fieldName
    const descriptionItem = this.patientService
      .definitionsSignal()
      .find(
        (item) =>
          item.fieldName === this.fieldName &&
          item.area === this.area &&
          item.isActive
      );

    // Customize the tooltip content dynamically
    if (descriptionItem) {
      let tooltipText = descriptionItem.description;

      if (descriptionItem.examples !== null) {
        tooltipText += `\n\n\n${descriptionItem.examples}`;
      }

      this.description = tooltipText;
    }
  }
}
