import { Component, Input } from '@angular/core';
import { IMedicationDetailed } from 'src/app/interfaces/Medication/IMedicationDetailed';

@Component({
  selector: 'app-medication-expand',
  templateUrl: './medication-expand.component.html',
  styleUrls: ['./medication-expand.component.css'],
})
export class MedicationExpandComponent {
  @Input() item: IMedicationDetailed = {};
}
