import { Component, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TooltipComponent } from '../../../../../../../common/tooltip/tooltip.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { TravelHistoryService } from '../../../../../../../services/travelHistory.service';
import { ITravelHistoryDropdown } from '../../../../../../../interfaces/TravelHistory/ITravelHistoryDropdown';
import { IAddTravelHistory } from '../../../../../../../interfaces/TravelHistory/IAddTravelHistory';

@Component({
  standalone: true,
  selector: 'app-travel-history-add-edit',
  templateUrl: './travel-history-add-edit.component.html',
  styleUrls: ['./travel-history-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
  ],
})
export class TravelHistoryAddEditComponent {
  private destroy$ = new Subject<void>();

  travelHistoryDropdown: ITravelHistoryDropdown | undefined = undefined;

  isVisible = false;
  isLoading = false;

  isEdit = false;
  editTravelHistoryId?: number = undefined;

  travelHistorySubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  travelHistoryForm: FormGroup = new FormGroup({});

  constructor(
    private readonly travelHistoryService: TravelHistoryService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;

    this.travelHistoryService.getTravelHistoryDropdown().subscribe({
      next: (pro) => {
        this.travelHistoryDropdown = pro?.data;
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
      },
    });

    this.travelHistoryForm = this.formBuilder.group({
      arrivalDateValue: [null, Validators.required],
      departureDateValue: [null, Validators.required],
      countryValue: [null, Validators.required],
    });

    this.travelHistoryService.travelHistoryEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editTravelHistoryId = data?.id;

          this.travelHistoryForm.patchValue({
            arrivalDateValue: data?.arrivalDate,
            departureDateValue: data?.departureDate,
            countryValue: data?.countryId,
          });
        } else {
          this.isEdit = false;
          this.editTravelHistoryId = undefined;

          this.travelHistorySubmitted = false;

          this?.travelHistoryForm.reset();
        }
      });

    this.travelHistoryService.travelHistoryModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  handleCancel() {
    this.travelHistoryForm.reset();

    this.travelHistoryService.travelHistoryModalSub.next(false);

    this.travelHistorySubmitted = false;

    this.isLoading = false;

    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.travelHistorySubmitted = true;

    if (this.travelHistoryForm.valid) {
      const travelHistoryObj: IAddTravelHistory = {};
      travelHistoryObj.id = this.editTravelHistoryId;
      travelHistoryObj.arrivalDate =
        this.travelHistoryForm.value?.arrivalDateValue;
      travelHistoryObj.departureDate =
        this.travelHistoryForm.value?.departureDateValue;
      travelHistoryObj.countryId = this.travelHistoryForm.value?.countryValue;

      if (this.isEdit) {
        this.travelHistoryService
          .editTravelHistory(travelHistoryObj)
          .subscribe({
            next: () => {
              Swal.fire({
                text: 'Travel history edited successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.travelHistoryService.getData();

              this.handleCancel();
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to edit travel history!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
              console.log(err);

              this.handleCancel();
            },
          });
      } else {
        this.travelHistoryService.addTravelHistory(travelHistoryObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Travel history added successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.travelHistoryService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to add travel history!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.log(err);
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }
}
