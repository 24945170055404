export interface IReferenceValue {
  min?: number;
  mid?: number;
  max?: number;
}

export const vitalSignsRV: IReferenceValue[] = [
  {
    min: undefined,
    max: undefined,
  },
  {
    min: 36.1,
    max: 37.2,
  },
  {
    min: 16,
    max: 20,
  },
  {
    min: 60,
    max: 100,
  },
  {
    min: 90,
    max: 140,
  },
  {
    min: 75,
    max: 90,
  },
  {
    min: undefined,
    max: undefined,
  },
  {
    min: 93,
    max: undefined,
  },
];

export const abgRV: IReferenceValue[] = [
  {
    min: undefined,
    max: undefined,
  },
  {
    min: 7.35,
    max: 7.45,
  },
  {
    min: 22,
    max: 26,
  },
  {
    min: 35,
    max: 45,
  },
  {
    min: 75,
    max: 100,
  },
  {
    min: 95,
    max: 100,
  },
];

export const glasgowScoreRV: IReferenceValue = {
  min: 3,
  mid: 9,
  max: 13,
};

export const glasgowRV: IReferenceValue[] = [
  {
    min: undefined,
    max: undefined,
  },
  {
    min: undefined,
    max: undefined,
  },
  {
    min: undefined,
    max: undefined,
  },
  {
    min: undefined,
    max: undefined,
  },
  glasgowScoreRV,
];

export const ardsRV: IReferenceValue = {
  min: 0,
  mid: 100,
  max: 200,
};

export const mewsRV: IReferenceValue = {
  min: 0,
  mid: 5,
  max: 7,
};
