import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IModule } from 'src/app/interfaces/IModule';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { paths } from 'src/component-paths/component-paths';

@Component({
  selector: 'app-tracheostomy2-section',
  templateUrl: './tracheostomy2-section.component.html',
  styleUrls: ['./tracheostomy2-section.component.css'],
})
export class Tracheostomy2SectionComponent {
  @Input() submenu?: IModule[];

  public componentPaths = paths;

  constructor(
    private readonly eocService: EpisodeOfCareService,
    private readonly router: Router
  ) {}

  ngOnInit() {}

  isInpatientView() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }
}
