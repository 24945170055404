import {Component} from '@angular/core';

@Component({
  selector: 'app-health-care-plan-section',
  templateUrl: './health-care-plan-section.component.html',
  styleUrls: ['./health-care-plan-section.component.css']
})
export class HealthCarePlanSectionComponent {

}
