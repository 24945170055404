import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableModule } from 'ng-zorro-antd/table';
import { Subject, takeUntil } from 'rxjs';
import { TooltipComponent } from 'src/app/common/tooltip/tooltip.component';
import { IAddCtParameters } from 'src/app/interfaces/CtParameters/IAddCtParameters';
import { CtParametersService } from 'src/app/services/ctparameters.service';
import Swal from 'sweetalert2';

@Component({
  standalone: true,
  selector: 'app-ct-parameters-add-edit',
  templateUrl: './ct-parameters-add-edit.component.html',
  styleUrls: ['./ct-parameters-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
  ],
})
export class CtParametersAddEditComponent {
  private destroy$ = new Subject<void>();

  isVisible = false;
  isLoading = false;
  isDropdownLoading = false;

  isEdit = false;
  editCtParametersId?: number;

  ctParametersSubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  ctParametersForm: FormGroup = new FormGroup({});

  constructor(
    private readonly ctParametersService: CtParametersService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isEdit = false;
    this.isLoading = true;
    this.isDropdownLoading = true;

    this.ctParametersForm = this.formBuilder.group({
      depthOfTracheaValue: [null, Validators.required],
      distanceFromSkinToTracheaValue: [null, Validators.required],
      distanceFromCricothyroidToCarinaValue: [null, Validators.required],
      distanceBetweenTrachealCartilagesValue: [null, Validators.required],
      anatomicalVariationsValue: [null, Validators.required],
    });

    this.ctParametersService.ctParametersEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.isLoading = false;

          this.editCtParametersId = data?.id;

          this.ctParametersForm.patchValue({
            depthOfTracheaValue: data?.depthOfTrachea,
            distanceFromSkinToTracheaValue: data?.distanceFromSkinToTrachea,
            distanceFromCricothyroidToCarinaValue:
              data?.distanceFromCricothyroidToCarina,
            distanceBetweenTrachealCartilagesValue:
              data?.distanceBetweenTrachealCartilages,
            anatomicalVariationsValue: data?.anatomicalVariations,
          });
        } else {
          this.isEdit = false;
          this.isLoading = false;

          this.editCtParametersId = undefined;

          this?.ctParametersForm.reset();
        }
      });

    this.ctParametersService.ctParametersModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  handleCancel() {
    this.ctParametersForm.reset();

    this.ctParametersService.ctParametersModalSub.next(false);

    this.ctParametersSubmitted = false;

    this.isLoading = false;

    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.ctParametersSubmitted = true;

    if (this.ctParametersForm.valid) {
      const ctParametersObj: IAddCtParameters = {};

      ctParametersObj.id = this.editCtParametersId;

      ctParametersObj.depthOfTrachea = this.ctParametersForm.get(
        'depthOfTracheaValue'
      )?.value;
      ctParametersObj.distanceFromSkinToTrachea = this.ctParametersForm.get(
        'distanceFromSkinToTracheaValue'
      )?.value;
      ctParametersObj.distanceFromCricothyroidToCarina =
        this.ctParametersForm.get(
          'distanceFromCricothyroidToCarinaValue'
        )?.value;
      ctParametersObj.distanceBetweenTrachealCartilages =
        this.ctParametersForm.get(
          'distanceBetweenTrachealCartilagesValue'
        )?.value;
      ctParametersObj.anatomicalVariations = this.ctParametersForm.get(
        'anatomicalVariationsValue'
      )?.value;

      if (this.isEdit) {
        this.ctParametersService.editCtParameters(ctParametersObj).subscribe({
          next: () => {
            Swal.fire({
              text: '3D-CT Parameters record edited successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.ctParametersService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to edit 3D-CT Parameters record!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.log(err);

            this.handleCancel();
          },
        });
      } else {
        this.ctParametersService.addCtParameters(ctParametersObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'CtParameters record added successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.ctParametersService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to add 3D-CT Parameters record!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.log(err);
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }
}
