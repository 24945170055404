import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  constructor(
    private readonly keycloak: KeycloakService,
    private router: Router
  ) {}

  async ngOnInit() {
    if (await this.keycloak.isLoggedIn()) {
      this.router.navigate(['/ehr']);
    } else {
      this.keycloak.login({
        redirectUri: environment.APP_URL + 'ehr',
      });
    }
  }
}
