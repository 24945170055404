import { Component } from '@angular/core';

@Component({
  selector: 'app-complication-section',
  templateUrl: './complication-section.component.html',
  styleUrls: ['./complication-section.component.css']
})
export class ComplicationSectionComponent {

}
